import gsap from 'gsap';

export default class NavMenu {

    constructor(page) {

        this.navbar = $('#navbar');
        this.button = $('#navbar-toggle');
        this.menu = this.navbar.children('.navbar__menu').e();

        this.animationDefaults = { defaults: { ease: 'power1.out' } };

        this.isOpen = false;
        this.page = page;

    }

    init() {

        // 1. Attach Event Listener on Toggle Button
        this.button.click(() => this.toggle());


        // 2. Add Links
        this.links = this.navbar.children('a');
        this.hrefBase = this.hrefBase || this.links.first.href;


        // 3. Save Original URLs in Data Attributes
        this.links.forEach(link => link.setAttribute('data-href', link.href));


        // 4. Set Page Anchors
        this.setAnchors();

    }

    setAnchors() {

        this.links.forEach(link => {

            if (link.dataset.ns !== this.page.state.namespace) return;

            link.href = '#';

            link.classList.add('active');

        });

    }

    reset() {
        this.links = this.navbar.children('a');
        this.links.forEach(link => {
            link.href = link.dataset.href;

            if (link.dataset.relativeLink) link.href = link.dataset.relativeLink;

            link.classList.remove('active');
        });
    }

    refresh() {

        this.links = this.navbar.children('a');

        this.setAnchors();
    }

    toggle() {

        if (this.isOpen) this.close();

        else this.open();

    }

    open() {
        if (this.isOpen) return;

        this.isOpen = this.page.state.menuOpen = true;
        this.menu.classList.add('open');
        this.button.addClass('menu-is-open');
        this.navbar.addClass('menu-is-open');

        this.page.scroll.pause();

        const tl = gsap.timeline(this.animationDefaults);

        tl.to(this.menu, { opacity: 1, backdropFilter: 'blur(40px)', duration: .75 });
        tl.to('.navbar__menu-links, .navbar__menu-social', { opacity: 1, duration: .75 }, "-=.3");
        
    }

    async close(delay) {
        if (!this.isOpen) return;

        this.isOpen = this.page.state.menuOpen = false;
        this.page.state.menuOpen = this.isOpen;
        this.menu.classList.remove('open');
        this.button.removeClass('menu-is-open');

        const tl = gsap.timeline(this.animationDefaults);

        tl.to('.navbar__menu-links, .navbar__menu-social', { opacity: 0, duration: .7 });
        tl.to(this.menu, {opacity: 0, backdropFilter: 'blur(0)', duration: 1 });

        await $.delay(700);

        this.navbar.removeClass('menu-is-open');
        this.page.scroll.resume();

        await $.delay(delay ?? 1000);

    }

}