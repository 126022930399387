import gsap from 'gsap';

export default class Preloader {

    constructor(options = {}) {

        this.animations = options.showAnimations ?? true;
        this.baseInterval = 33;
        this.progress = 0;
        this.pageState = 'loading';

        this.showWelcomeText = options.showWelcomeText ?? window.showGreeting == 'true';
        this.skipGreeting = window.sessionStorage.getItem('skipGreeting');

        this.callbacks = [];
        
    }

    init() {

        $(document).on('readystatechange', () => this.onPageStateChange());

        if (this.animations) this.animateLoader();

    }

    onPageStateChange() {

        this.pageState = document.readyState;

        if (this.pageState === 'complete' && !this.animations) this.onPageLoad();

    }

    onPageLoad() {

        const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
        const duration = 1;
        const elements = $('#navbar, .transition-into-view').e();
        let delay = .75;

        if (this.animations) {

            // Fade Out Preloader
            tl.to('.preloader__progress', { opacity: 0, duration: .75, delay: 1 });

            // Display Greeting Text
            if (this.showWelcomeText && !this.skipGreeting) {
                tl.to('.preloader__text span', { y: '0%', duration: 1 , stagger: .25 });
                delay = 2;
                window.sessionStorage.setItem('skipGreeting', true);
            }

            
            tl.to('.preloader', { x: '-100%', duration: 1, delay });
            tl.fromTo(elements, { x: '50vw' }, { x: '0vw', duration }, `-=${duration}`);

            //tl.to('.preloader', { x: '-100%', duration: .65 }, "-=.6");

        }

        else {
            tl.to('.preloader', { x: '-100%', duration: 0 });
            tl.fromTo('main', { opacity: 0 }, { opacity: 1, duration: 1 });
        }


        // Trigger Even Callbacks
        tl.eventCallback('onComplete', () => this.onComplete());


        // Execute Callback Functions
        $.delay(this.animations ? 4000 : 200).then(() => this.callbacks.forEach(callback => callback.fn()));

    }

    onComplete() {

        $('.preloader, .preloader__slider').remove();

    }

    async animateLoader() {

        // Define Interval To Be Used
        let interval = this.baseInterval;

        // Get Circle & It's Circumference
        const circle = $('.progress-ring__circle').e();
        const circumference = circle.r.baseVal.value * 2 * Math.PI;

        // Apply Offset To Circle To Prepare For Display
        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = circumference;
        circle.style.opacity = 1;

        // Define Function That Increments Circle
        const updateInterval = () => {

            let p = this.progress + 1;

            if (this.pageState === 'complete') {

                const speed = 1 - (Math.floor(p / 10) / 10);

                circle.style.transition = `stroke-dashoffset ${speed}s, opacity .3s`;
                p = 100;
            }

            else {
                
                if (p <= 30) interval +=1;
                else if (p <= 60) interval += 2;
                else if (p <= 80) interval += 10;
                else if (p <= 90) interval += 40;
                else if (p <= 95) interval += 80;
                else if (p <= 99) interval += 150;

                if (p === 100) p--;

            }

            this.progress = p;

            render();

        };


        // Define Function That Renders Percentages
        const render = async () => {

            await $.delay(interval);

            const offset = circumference - this.progress / 100 * circumference;
            circle.style.strokeDashoffset = offset;

            if (this.progress < 100) updateInterval();

            else this.onPageLoad();

        };

        // Begin Circle Animation
        render();

    }

    after(callback) {

        this.callbacks.push({ fn: callback });

    }

}