// Import Runtime
import 'regenerator-runtime/runtime';

// Import Dreams JS and set Global Scope
import { dream } from './Dreams';
window.$ = dream;

// Import Packages
import barba from '@barba/core';
import gsap from 'gsap';

// Import Modules
import Preloader from './Preloader';
import Scroll from './Scroll';
import Cursor from './Cursor';
import NavMenu from './NavMenu';


// Assign Global Variables
const gsapDefaults = { defaults: { ease: 'power1.inOut' } };



class Page {

    constructor() {

        this.preloader = new Preloader({
            showAnimations: true
        });


        this.scroll = new Scroll(this);
        this.cursor = new Cursor();
        this.navMenu = new NavMenu(this);

        let url = window.location.href.split(/[?#]/)[0];

        while (url.charAt(url.length - 1) === '/') {
            url = url.substr(0, url.length - 1);
        }

        this.url = url;

        window.history.replaceState(null, '', this.url);

        this.state = {
            menuOpen: false,
            isMobile: null,
            hasCustomCursor: false,
            namespace: '',
            alerting: false
        };

    }


    init() {

        // 1. Subscribe To Events After Preloader Has Loaded
        this.preloader.after(() => this.afterInitialLoad());


        // 2. Initialized Preloader Animations
        this.preloader.init();


        // 3. Set Initial Namespace
        this.state.namespace = $('main').data('barbaNamespace');


        // 4. Define Body & Main Element
        this.main = $('main').e();
        this.body = $('body');


    }


    freeze() {

        return new Promise(resolve => {

            this.navMenu.reset();
            this.scroll.pause();
            this.cursor.pause();

            this.navMenu.close(0).then(resolve);
        });
        
    }


    start() {
        this.navMenu.refresh();
        this.scroll.refresh(this.main);
        if (this.state.hasCustomCursor) this.cursor.refresh(this.main);
        if (this.state.isMobile) this.scroll.to('top', 0);

        $('video[autoplay]').forEach(video => video.play());
    }


    refresh(next) {

        // 1. Remove Initial Namespace
        this.body.removeClass(this.state.namespace);

        // 2. Set New Namespace
        this.state.namespace = next.namespace;
        this.url = next.url.href;
        this.body.addClass(next.namespace);


        // 3. Hide Old Main Container
        this.main.style.display = 'none';


        // 4. Update Main Element
        this.main = next.container;


        // 5. Scroll To Top
        if (this.state.isMobile) this.scroll.to('top', 0);


        // 6. Reattach Events
        this.mountEvents();

    }


    afterInitialLoad() {

        this.navMenu.init();
        this.scroll.init(this.main);
        this.state.isMobile = this.scroll.isMobile;

        // Enable Custom Cursor If Applicible
        if (!this.state.isMobile)  {
            this.cursor.init(this.main);
            this.state.hasCustomCursor = true;
        }

        

        this.mountEvents();

        this.showSnackbar();

    }


    mountEvents() {

        $(".alert-snackbar__close").on('click', e => this.hideSnackbar());
        
        $(this.main).children('.hover-coming-soon').on('click', () => this.showAlert(
            'Project Coming Soon',
            'I like writing very detailed case studies which take time. If you are intersted in learning more about this project please contact me and I can give you all of the details direcctly.'
        ));

    }


    async hideAlert(alert, scrollTo) {

        // Resume Scroll Functions & Scroll to target
        this.scroll.resume();
        if (scrollTo) this.scroll.to(scrollTo);
        

        // Animate the Alert Out
        await new Promise(resolve => {

            const tl = gsap.timeline(gsapDefaults);

            tl.to(alert.children('.alert__container, .alert__close').e(), { opacity: 0, duration: .75 });
            tl.to(alert.e(), { opacity: 0, backdropFilter: 'blur(0px)', duration: .75 });
            tl.eventCallback('onComplete', resolve)

        });

        alert.remove();

        this.state.alerting = false;

    }


    showAlert(title, text) {

        if (!this.state.alerting) this.state.alerting = true;
        else return;

        // Create Alert HTML
        const alert = $.html(`
            <section class="alert">
                <div class="alert__container">
                    <h3 class="alert__title">${title}</h3>
                    <p class="alert__text">${text}</p>
                    <button class="outline-btn adjust-font">Contact Me</button>
                </div>
                <div class="alert__close">
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='18' d='M368 368L144 144M368 144L144 368'/></svg>
                </div>
            </section>
        `);


        // Append Alert
        this.body.append(alert);


        // Pause Page Scrolling
        this.scroll.pause();


        // Attach Event Listeners
        alert.children('.alert__close').on('click', () => this.hideAlert(alert));
        alert.children('.outline-btn').on('click', () => this.hideAlert(alert, '#contact-target'));


        // Animate In Content
        const tl = gsap.timeline(gsapDefaults);
        tl.to(alert.e(), { opacity: 1, backdropFilter: 'blur(40px)', duration: .75 });
        tl.fromTo(alert.children('.alert__container, .alert__close').e(), { opacity: 0 }, { opacity: 1, duration: .75 });


    }

    
    showSnackbar() {

        const snackbar = $(".alert-snackbar").e()

        // Create Animation Timeline
        const tl = gsap.timeline(gsapDefaults);

        tl.fromTo(snackbar, {
            y: snackbar.offsetHeight,
            opacity: 0
        }, {
            y: 0,
            opacity: 1,
            duration: 0.3
        })

        tl.fromTo(snackbar, {
            scale: 0.6
        }, {
            scale: 1,
            duration: 0.2
        })
        
    }

    hideSnackbar() {

        const snackbar = $(".alert-snackbar").e()

        // Create Animation Timeline
        const tl = gsap.timeline(gsapDefaults);

        tl.to(snackbar, {
            scale: 0.6,
            duration: 0.2
        })

        tl.to(snackbar, {
            y: snackbar.offsetHeight,
            opacity: 0,
            duration: 0.3
        })

        tl.eventCallback('onComplete', () => {
            snackbar.classList.add('hidden');
        });


    }

}



window.addEventListener('DOMContentLoaded', () => {

    const page = new Page();

    const cases = ['united-travel', 'linear-led', 'recite-learning'];
    const projects = ['day-fiftyfive', 'you-matter-therapy', 'son-oilfield'];

    page.init();
    
    barba.init({
        transitions: [

            // Blur Transition
            {
                name: 'blur-fade',
                before({ next }) {
                    return new Promise(resolve => {

                        page.slide = $.html(`<div class="transition-glass"><h2 class="h3 uppercase">${next.container.dataset.slideTitle}</h2></div>`)

                        page.body.append(page.slide);

                        resolve();

                    });
                },
                leave({ current }) {
                    return new Promise(resolve => {

                        const tl = gsap.timeline(gsapDefaults);
                        
                        tl.to(page.slide.e(), { opacity: 1, backdropFilter: 'blur(40px)', duration: .75 });
                        tl.to(page.slide.children('h2').e(), { opacity: 1, duration: .75 });
                        tl.to(current.container, { opacity: 0, duration: 1 });
                        tl.eventCallback('onComplete', resolve);

                    });
                },
                enter() {

                    return new Promise(resolve => {

                        page.slide.addClass('opaque');
                        resolve();

                    });
                    
                },
                after({ next }) {

                    return new Promise(resolve => {

                        page.slide.removeClass('opaque');

                        const tl = gsap.timeline(gsapDefaults);
                        tl.to(page.slide.children('h2').e(), { opacity: 0, duration: .75 });
                        tl.fromTo(next.container, { opacity: 0 }, { opacity: 1, duration: 1 });
                        tl.to(page.slide.e(), { opacity: 0, backdropFilter: 'blur(0px)', duration: .75 }, '-=.7');
                        tl.eventCallback('onComplete', () => {
                            if (page.slide) {
                                page.slide.remove();
                                page.slide = undefined;
                            }
                            resolve();
                        });

                        

                    });

                }
            },

            // Transition In
            {
                name: 'project-transition-in',
                to: { namespace: cases.concat(projects) },
                before({next}) {

                    return new Promise(resolve => {

                        $('#t-subtitle').text(next.container.dataset.slideSubtitle || '');
                        $('#t-title > span').text(next.container.dataset.slideTitle || '');

                        resolve();

                    });

                },
                leave() {
                    return new Promise(resolve => {

                        const duration = 1;

                        // Get Inview Items
                        const visibleItems = document.querySelectorAll('.transition-visible-item, #navbar');

                        // Create Timeline
                        const tl = gsap.timeline(gsapDefaults);

                        tl.fromTo('.transition-slider', { x: '100%', y: '0vh' }, { x: '0%', duration });
                        tl.to(visibleItems, { x: '-50vw', duration }, `-=${duration}`);
                        
                        tl.fromTo('#t-title > span', { y: '100%', opacity: 1 }, { y: '0%', duration: .7, ease: 'power1.out', delay: .5 });

                        tl.fromTo('#t-subtitle', { opacity: 0 }, { opacity: 1, duration: .7 });

                        // Resolve Promise
                        tl.eventCallback('onComplete', () => $.delay(1000).then(resolve));

                    });
                },
                enter() {

                    return new Promise(resolve => {

                        gsap.to('#t-title span, #t-subtitle', { opacity: 0, duration: .7, onComplete: () => resolve() })

                    });

                },
                afterEnter({ next }) {
                    return new Promise(resolve => {

                        const images = next.container.querySelectorAll('img');
                        let i = 0;

                        images.forEach(image => {

                            if (image.complete) i++;

                            else image.addEventListener('load', () => {
                                i++;
                                if (i === images.length) resolve();
                            });

                            if (i === images.length) resolve();

                        });

                    });
                },
                after({ next }) {
                    return new Promise(resolve => {

                        const duration = 1;

                        const elements = Array.from(next.container.querySelectorAll('.transition-into-view')).concat(document.querySelectorAll('#navbar'));

                        const tl = gsap.timeline(gsapDefaults);

                        tl.to('.transition-slider', { x: '-100%', duration });

                        tl.fromTo(elements, { x: '50vw' }, { x: '0vw', duration }, `-=${duration}`);

                        tl.eventCallback('onComplete', () => resolve());

                    });
                    
                }
            },

            // Transition Out
            {
                name: 'project-transition-out',
                from: { namespace: cases.concat(projects) },
                leave() {
                    return new Promise(resolve => {

                        const tl = gsap.timeline(gsapDefaults);
                        const duration = 1;

                        // Get Inview Items
                        const visibleItems = document.querySelectorAll('.transition-visible-item, #navbar');

                        // Transition Slider
                        tl.fromTo('.transition-slider', { x: '-100%', y: '0vh' }, { x: '0%', duration });
                        tl.to(visibleItems, { x: '50vw', duration }, `-=${duration}`);


                        // Resolve Promise
                        tl.eventCallback('onComplete', () => $.delay(1000).then(resolve));

                    });
                },
                after({ next }) {

                    return new Promise(resolve => {
                        const duration = 1;

                        const elements = Array.from(next.container.querySelectorAll('.transition-into-view')).concat(document.querySelectorAll('#navbar'));

                        const tl = gsap.timeline(gsapDefaults);

                        tl.to('.transition-slider', { x: '100%', duration });

                        tl.fromTo(elements, { x: '-50vw' }, { x: '0vw', duration }, `-=${duration}`);

                        tl.eventCallback('onComplete', () => resolve());
                    });
                        
                }
            }

        ]
    });


    barba.hooks.beforeLeave(() => page.freeze());
    barba.hooks.afterLeave(({next}) => page.refresh(next));
    barba.hooks.after(() => page.start());

});