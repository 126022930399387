import LocomotiveScroll from 'locomotive-scroll';

export default class Scroll {

    constructor(page) {

        const isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        this.scrollMultiplier = isMac && isFirefox ? 4.8 : 1.2;
        this.smoothScrollMultiplier = .25;

        this.page = page;

    }
    

    init(container) {

        // Define Elements
        this.body = $('body');
        this.container = container ?? $('main').e();
        this.links = $(this.container).children('a').concat($('#navbar a').kill());
        

        // Attach Scroll Events
        this.attachEvents();


        // Determine If Mobile
        this.isMobile = this.locomotive.isMobile;


        // Remove Fixed Class If Mobile
        if (this.isMobile) this.body.removeClass('fixed');

    }


    refresh(container) {

        this.locomotive.destroy();

        this.init(container);

    }


    attachEvents() {

        // Create New Locomotive Scroll Instance
        this.locomotive = new LocomotiveScroll({
            el: this.container,
            smooth: true,
            multiplier: this.scrollMultiplier,
            lerp: .07,
            scrollFromAnywhere: true
        });
        

        // Attach Scroll Event Listeners
        this.links.filter(link => link.href.includes('#')).prevent('click', e => {

            const href = e.target.closest('a').href.split('#')[1];
            const target = href ? `#${href}` : 'top';
            const currentPosition = this.locomotive.scroll.instance.scroll.y;
            const targetPosition = href ? $(target).position().top : 0;
            const delta = Math.abs(currentPosition - targetPosition);
            const duration = Math.floor(delta * this.smoothScrollMultiplier);

            this.page.navMenu.close(200).then(() => this.to(target, duration));
            
        });

 
    }


    update() {
        this.locomotive.update();
    }


    pause() {
        this.locomotive.stop();

        if (this.isMobile) this.body.addClass('fixed');
    }

    
    resume() {
        this.locomotive.start();
        if (this.isMobile) this.body.removeClass('fixed');
    }


    to(target, duration) {

        this.locomotive.scrollTo(target, 0, duration ?? 1000);

    }

}