import gsap from 'gsap';

export default class Cursor {

    constructor() {

        // Define Cursort Window Padding
        this.padding = 20;


        // Define Initial Cursor Scale
        this.scale = 1;

    }

    async init(container) {

        // 1. Define Cursor Element
        this.element = $('#dynamic-cursor').e();


        // 2. Get Window Dimensions 
        this.getWindowDimensions(); 


        // 3. Listen For Window Resize Event And Update Bounds
        $(window).on('resize', () => this.getWindowDimensions());


        // 4. Track Position Of Mouse
        $(window).on('mousemove', e => this.animate(e.clientX, e.clientY));


        // 5. Attach Event Listeners for Cursor Effects
        this.update(container);

    }

    getWindowDimensions() {

        this.bounds = {
            top: this.padding,
            bottom: window.innerHeight - this.padding,
            left: this.padding,
            right: window.innerWidth - this.padding
        };

    }

    update(container) {

        const c = $(container);
    
        // 1. Define Elements
        this.cases = c.children('.hover-case');
        this.projects = c.children('.hover-project');
        this.upcoming = c.children('.hover-coming-soon');
        this.icons = c.children('.icon-btn, .link-btn, .no-cursor').concat($('.navbar__brand, .navbar__links, .alert-snackbar__close'));
        this.zones = this.cases.concat(this.projects).concat(this.upcoming);
        

        // 2. Attach Project & Case Study Cursor Listeners
        this.cases.on('mouseenter', () => this.changeCursor('case'));
        this.projects.on('mouseenter', () =>this.changeCursor('project'));
        this.upcoming.on('mouseenter', () => this.changeCursor('none'))
        this.zones.on('mouseleave', () => this.changeCursor('ring'));


        // 3. Attach Shrink Cursor Listeners
        this.icons.on('mouseenter', () => this.scale = 0);
        this.icons.on('mouseleave', () => this.scale = 1);


        // 4. Reset Scale
        this.scale = 1;

    }

    refresh(container) {
        this.update(container);
    }

    changeCursor(state) {

        if (state === 'ring') this.element.classList.remove('project', 'case', 'none');

        else this.element.classList.add(state);

    }

    pause() {
        this.scale = 0;
    }


    animate(x, y) {

        const outOfBounds = y < this.bounds.top || y > this.bounds.bottom || x < this.bounds.left || x > this.bounds.right;

        gsap.to(this.element, {
            x,
            y,
            scale: outOfBounds ? 0 : this.scale,
            opacity: 1,
            duration: .25,
            ease: 'power1.out'
        });

    }

}
